<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>      
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Incapacidades'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="true"
                    >
                        <template slot="actionButtonsLeft">
                            <v-btn 
                                color="#2DB9D1" 
                                class="btnAzul elevation-0 px-10 mr-4"
                                right
                                rounded 
                                @click="exportar()"
                            >
                                Exportar ZIP
                            </v-btn>
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Cliente"
                                    :items="itemsClientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="incapacidad.cliente_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Empresa"
                                    :items="itemsEmpresas"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="incapacidad.empresa_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Registro patronal"
                                    :items="itemsRegistrosPatronales"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="registro_patronal_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'admin'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Empresa"
                                    :items="itemsEmpresas"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="incapacidad.empresa_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Sucursal"
                                    :items="itemsSucursales"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="incapacidad.sucursal_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Empleados"
                                    :items="itemsEmpleados"
                                    item-text="nombre_completo"
                                    item-value="id"
                                    persistent-hint
                                    v-model="incapacidad.empleado_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="incapacidad.fecha_inicio"
                                    :valorDefecto="incapacidad.fecha_inicio"
                                    label="Fecha inicio"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicio"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="incapacidad.fecha_fin"
                                    :valorDefecto="incapacidad.fecha_fin"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Estatus"
                                    :items="estatusItems"
                                    item-text="nombre"
                                    item-value="valor"
                                    persistent-hint
                                    v-model="incapacidad.estatus"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Ramo de seguro"
                                    :items="itemsTiposIncapacidades"
                                    item-text="descripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="incapacidad.SAT_tipo_incapacidad_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>  

                            <v-col v-if="showCampo" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo de riesgo"
                                    :items="itemsTiposRiesgos"
                                    item-text="descripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="incapacidad.tipo_riesgo_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="showCampo" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Secuela o consecuencia"
                                    :items="itemsSecuelasConsecuencias"
                                    item-text="descripcion"
                                    item-value="consecuencia_id"
                                    persistent-hint
                                    v-model="incapacidad.tipo_consecuencia_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>

                            <!-- Se cambia la leyenda de "Rama" a "Control" -->           
                            <v-col cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Control"
                                    :items="itemsControl"
                                    item-text="nombre"
                                    item-value="rama_id"
                                    persistent-hint
                                    v-model="incapacidad.nomina_incapacidad_rama_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>

                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">

                                <expandable-item 
                                    :item="item"
                                    :key="item.id" 
                                    @openModalInfo="openModalInfo"
                                    @abrirModalUpdate="abrirModalUpdate"
                                    @eliminar="eliminar"
                                >
                                </expandable-item>
                                
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <pre>{{$data.encapacidad}}</pre>
                                        <v-row>
                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Cliente"
                                                        :items="itemsClientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="incapacidad.cliente_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empresa"
                                                        :items="itemsEmpresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="incapacidad.empresa_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Sucursal" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Sucursal"
                                                        :items="itemsSucursales"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="incapacidad.sucursal_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empleado" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empleados"
                                                        :items="itemsEmpleados"
                                                        item-text="nombre_completo"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="incapacidad.empleado_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            
                                            

                                            <!-- Se cambia la leyenda de "Tipo incapacidad" a "Ramo de seguro" -->
                                            <v-col cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Ramo de seguro" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Ramo de seguro"
                                                        :items="itemsTiposIncapacidades"
                                                        item-text="descripcion"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="incapacidad.SAT_tipo_incapacidad_id"
                                                        class="pa-0 ma-0"
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="showCampo" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Tipo de riesgo" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Tipo de riesgo"
                                                        :items="itemsTiposRiesgos"
                                                        item-text="descripcion"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="incapacidad.tipo_riesgo_id"
                                                        class="pa-0 ma-0"
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <!-- <v-col v-if="!showCampo" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Tipo de riesgo"
                                                        :items="itemsTiposRiesgos"
                                                        item-text="descripcion"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="incapacidad.tipo_riesgo_id"
                                                        class="pa-0 ma-0"
                                                        disabled
                                                    >
                                                    </v-autocomplete>
                                            </v-col> -->
                                            <v-col v-if="showCampo" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Secuela o consecuencia" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Secuela o consecuencia"
                                                        :items="itemsSecuelasConsecuencias"
                                                        item-text="descripcion"
                                                        item-value="consecuencia_id"
                                                        persistent-hint
                                                        v-model="incapacidad.tipo_consecuencia_id"
                                                        class="pa-0 ma-0"
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <!-- <v-col v-if="!showCampo" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Secuela o consecuencia"
                                                    :items="itemsSecuelasConsecuencias"
                                                    item-text="descripcion"
                                                    item-value="consecuencia_id"
                                                    persistent-hint
                                                    v-model="incapacidad.tipo_consecuencia_id"
                                                    class="pa-0 ma-0"
                                                    disabled
                                                >
                                                </v-autocomplete>
                                            </v-col> -->

                                            <!-- Se cambia la leyenda de "Rama" a "Control" -->
                                            <v-col cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Control" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Control"
                                                        :items="itemsControl"
                                                        item-text="nombre"
                                                        item-value="rama_id"
                                                        persistent-hint
                                                        v-model="incapacidad.nomina_incapacidad_rama_id"
                                                        class="pa-0 ma-0"
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col v-if="activarFolio" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Folio" rules="required">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Folio" 
                                                        class="pa-0 ma-0" 
                                                        v-model="incapacidad.folio" 
                                                        :error-messages="errors" 
                                                        maxlength="100"
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-else cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-text-field 
                                                    outlined 
                                                    label="Folio" 
                                                    class="pa-0 ma-0" 
                                                    v-model="incapacidad.folio" 
                                                    disabled
                                                >
                                                </v-text-field>
                                            </v-col>

                                            
                                            <v-col cols="12" xs="12" sm="5" md="5"  class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="incapacidad.fecha_inicio"
                                                    :valorDefecto="incapacidad.fecha_inicio"
                                                    label="Fecha inicio"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaInicio"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="2" md="2"  class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Días" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        v-model="dias"
                                                        label="Días"
                                                        v-mask="'###'"
                                                        :disabled="diasDisable"
                                                        oninput="if(Number(this.value) === Number(0)) this.value = null;"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="5"  class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="incapacidad.fecha_fin"
                                                    :valorDefecto="incapacidad.fecha_fin"
                                                    label="Fecha final"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaFinal"
                                                    disabled
                                                />
                                            </v-col>

                                            <v-col v-if="activarPorcentaje" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Porcentaje" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Porcentaje de incapacidad" 
                                                        suffix="%"
                                                        class="pa-0 ma-0" 
                                                        min=0
                                                        max=100
                                                        v-on:keypress="presicionNumeroPorcentaje($event,incapacidad.porcentaje)"
                                                        oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                        v-model="incapacidad.porcentaje"
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-else cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                <v-text-field 
                                                    outlined
                                                    label="Porcentaje de incapacidad" 
                                                    suffix="%"
                                                    class="pa-0 ma-0" 
                                                    min=0
                                                    max=100
                                                    v-on:keypress="presicionNumeroPorcentaje($event,incapacidad.porcentaje)"
                                                    oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                    v-model="incapacidad.porcentaje"
                                                    disabled
                                                >
                                                </v-text-field>
                                            </v-col>
                                            

                                            <v-col cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Estatus" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Estatus"
                                                        :items="estatusItems"
                                                        item-text="nombre"
                                                        item-value="valor"
                                                        persistent-hint
                                                        v-model="incapacidad.estatus"
                                                        class="pa-0 ma-0"
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            
                                            <v-col v-if="accion == 'add'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Archivo" rules="required">
                                                    <v-file-input
                                                        outlined
                                                        label="Seleccionar el documento"
                                                        v-model="archivo"
                                                        required
                                                        :error-messages="errors"
                                                        prepend-icon=""
                                                        append-icon="attach_file"
                                                        id="fileDocument"
                                                        @click:append="open"
                                                    >
                                                    </v-file-input>
                                                </ValidationProvider>
                                            </v-col>
                                             <v-col v-else cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivo"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </v-col>
                                            
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                 <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                        v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                                <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                        
                                    >
                                        El usuario no tiene permiso para editar el registro.
                                    </v-alert>
                                </div>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>


            <template class="borde-card">
                <v-dialog v-model="dialogConsultar" persistent max-width="500px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalConsultar"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card class="borde-card" elevation="0">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagenConsultar" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModalConsultar }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingConsultar">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingConsultar" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <template>
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <div class="mt-3">
                                                    <div class="row col-lg-12">
                                                        <p class="col-lg-4 py-0 px-0 subtitle">Empleado:</p>
                                                        <p class="col-lg-8 py-0 px-0 text">{{itemIncapacidad.empleado.nombre_completo != null ? itemIncapacidad.empleado.nombre_completo:"N/A"}}</p>
                                                        <p class="col-lg-4 py-0 px-0 subtitle">Empresa:</p>
                                                        <p class="col-lg-8 py-0 px-0 text">{{itemIncapacidad.empresa != null ? itemIncapacidad.empresa.nombre:"N/A"}}</p>
                                                        <p class="col-lg-4 py-0 px-0 subtitle">Sucursal:</p>
                                                        <p class="col-lg-8 py-0 px-0 text">{{itemIncapacidad.sucursal != null ? itemIncapacidad.sucursal.nombre:"N/A"}}</p>
                                                        <p class="col-lg-4 py-0 px-0 subtitle">Fecha inicio:</p>
                                                        <p class="col-lg-8 py-0 px-0 text">{{itemIncapacidad.fecha_inicio != null ? dateFormat(itemIncapacidad.fecha_inicio):"N/A"}}</p>
                                                        <p class="col-lg-4 py-0 px-0 subtitle">Fecha fin:</p>
                                                        <p class="col-lg-8 py-0 px-0 text">{{itemIncapacidad.fecha_fin != null ? dateFormat(itemIncapacidad.fecha_fin):"N/A"}}</p>
                                                        <p class="col-lg-4 py-0 px-0 subtitle">Estatus:</p>
                                                        <p class="col-lg-8 py-0 px-0 text">{{itemIncapacidad.estatus != null ? formatoEstatus(itemIncapacidad.estatus):"N/A"}}</p>
                                                        <p class="col-lg-4 py-0 px-0 subtitle">Folio:</p>
                                                        <p class="col-lg-8 py-0 px-0 text">{{itemIncapacidad.folio != null ? itemIncapacidad.folio:"N/A"}}</p>
                                                        <p class="col-lg-4 py-0 px-0 subtitle">Tipo incapacidad:</p>
                                                        <p class="col-lg-8 py-0 px-0 text">{{itemIncapacidad.tipoIncapacidad != null ? itemIncapacidad.tipoIncapacidad.descripcion:"N/A"}}</p>
                                                        <!-- Se cambia la leyenda de "Rama" a "Control" -->
                                                        <p class="col-lg-4 py-0 px-0 subtitle">Control:</p>
                                                        <p class="col-lg-8 py-0 px-0 text">{{itemIncapacidad.ramaIncapacidad != null ? itemIncapacidad.ramaIncapacidad.nombre:"N/A"}}</p>
                                                        <p class="col-lg-4 py-0 px-0 subtitle text-center d-flex align-center justify-start">Archivo:</p>
                                                        <p class="col-lg-8 py-0 px-0 text d-flex align-start justify-start">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" @click="descargar(itemIncapacidad)" :loading="isLoadingDownload" color="#1E2245" elevation="0" dark fab x-small>
                                                                    <v-icon>arrow_downward</v-icon>
                                                                </v-btn>
                                                                </template>
                                                                <span>Descargar documento</span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom >
                                                                <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" @click="abrirModalDoc(itemIncapacidad)" class="ml-2" :disabled="isLoadingDownload" color="#1E2245" elevation="0" dark fab x-small>
                                                                    <v-icon>cached</v-icon>
                                                                </v-btn>
                                                                </template>
                                                                <span>Actualizar documento</span>
                                                            </v-tooltip>
                                                        </p>
                                                    </div>
                                                </div>
                                                
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button 
                                    class="btnCerrar" 
                                    @click="cerrarModal()"
                                >
                                    Cerrar
                                </button>
                            </v-card-actions>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialogDocumento" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalDocumento"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="formDoc" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <h2 class="titleModal">{{ tituloModalDoc }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingDoc">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingDoc" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivo"
                                                    required 
                                                    :error-messages="errors"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModalDocumento()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardarDocumento()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Actualizar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/datatable/Datatable.vue';
import ExpandableItem from '@/components/ExpandableItemIncapacidades.vue';
import queries from '@/queries/Incapacidades';
import Notify from '@/plugins/notify';
import apiClientes from '@/api/clientes';
import apiEmpresas from '@/api/empresas';
import apiSucursales from '@/api/sucursales';
import apiIncapacidades from '@/api/nominas/incapacidades';
import apiIncapacidadesTipos from '@/api/nominas/incapacidadesTipos';
import apiIncapacidadesRamas from '@/api/nominas/incapacidadesRamas'; //apiAnteriorControl
import apiIncapacidadesTipoRiesgo from '@/api/nominas/incapacidadesTipoRiesgo';
import apiIncapacidadesSecuelaconsecuencia from '@/api/nominas/incapacidadesSecuelaConsecuencia';
import apiIncapacidadesControl from '@/api/nominas/incapacidadesControl';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import moment from 'moment';
moment.locale('es');


export default {
    components: {
        VuetifyDatePicker,
        ExpandableItem,
        'data-table': Datatable,
    },
    data() {
        return {
            datosLogin      : null,
            rol             : null,
            url             : 'nominas-incapacidades/find',
            columns         : [
                {
                    label: 'Empleado',
                    name: 'nombre',
                    filterable: false
                },
                {
                    label: 'Empresa',
                    name: 'empresa',
                    filterable: false
                },
                {
                    label: 'Sucursal',
                    name: 'sucursal',
                    filterable: false
                },
                {
                    label: 'Departamento',
                    name: 'departamento',
                    filterable: false
                },
                {
                    label: 'Tipo de nómina',
                    name: 'tipo',
                    filterable: false
                },
                {
                    label: "",
                    name: "desplegar",
                    align: 'center',
                    width:"8",
                    filterable: false,
                },
            ],
            filters         : {},
            filtersEmpleados: {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            incapacidad             : {
                id                  : null,
                cliente_id          : null,
                empresa_id          : null,
                sucursal_id         : null,
                empleado_id         : null,
                fecha_inicio        : null,
                fecha_fin           : null,
                estatus             : null,
                folio               : null,
                nomina_incapacidad_rama_id  : null, //control
                SAT_tipo_incapacidad_id  : null,
                tipo_riesgo_id      : null,
                tipo_consecuencia_id: null,
                porcentaje          : null,
                dias                : null,

            },
            archivo                     : null,
            accion                      : null,
            tituloModal                 : null,
            dialog                      : false,
            isSaving                    : false,
            imagen                      : '',
            cliente_value               : null,
            empleado_value              : null,
            itemsClientes               : [],
            itemsEmpresas               : [],
            itemsSucursales             : [],
            itemsEmpleados              : [],
            itemsTiposIncapacidades     : [],
            itemsTiposRiesgos           : [],
            itemsSecuelasConsecuencias  : [],
            itemsControl                : [],
            
            estatusItems: [
                {id:1, nombre:"Autorizado",valor:"AUTORIZADO"}, 
                {id:2, nombre:"Rechazado", valor:"RECHAZADO"},
                {id:3, nombre:"Pendiente", valor:"PENDIENTE"},
                {id:3, nombre:"Revisión", valor:"REVISION"}
            ],
            dialogConsultar         : false,
            imagenConsultar         : '/static/modal/incapacidadCreate.svg',
            tituloModalConsultar    : 'Información de la incapacidad',
            isLoadingConsultar      : false,
            isLoadingDownload       : false,
            dialogDocumento         : false,
            tituloModalDoc          : 'Actualizar documento',
            isLoadingDoc            : false,
            isLoadingModal          : false,
            itemIncapacidad         : {
                id                          : null,
                cliente_id                  : null,
                empresa_id                  : null,
                sucursal_id                 : null,
                empleado_id                 : null,
                SAT_tipo_incapacidad_id     : null,
                tipo_riesgo_id              : null,
                tipo_consecuencia_id        : null,
                nomina_incapacidad_rama_id  : null, //control
                fecha_inicio                : null,
                fecha_fin                   : null,
                estatus                     : null,
                folio                       : null,
                porcentaje                  : null,
                dias                        : null,
                empleado :{},
                cliente:{},
                empresa:{},
                sucursal:{},
                tipoIncapacidad:{},
                ramaIncapacidad:{}
            },
            idIncapacidad               : null,
            dias                        : null,
            diasDisable                 : true,
            showCampo                   : false,
            loadingLottie               : false,
            fraseLoading                : "Descargando",
            itemsRegistrosPatronales    : [],
            registro_patronal_id        : null,

            permisos :{
                create: false,
                update: false,
                delete: false,
            }
            
        }
    },
    watch : {
        'incapacidad.cliente_id':function(newData, oldData) {
            if(newData != null){
                this.cambiaCliente(newData);
                this.getRegistroPatronal();
            }
        }, 
        'incapacidad.empresa_id':function(newData, oldData) {
            if(newData != null){
                this.cambiaEmpresa(newData);
            }
        },
        'incapacidad.sucursal_id':function(newData, oldData) {
            if(newData != null){
                this.cambiaSucursal(newData);
            }
        },
        'incapacidad.fecha_inicio':function(newData, oldData) {
            if(newData == null || newData == '' || newData == undefined){
                this.diasDisable = true;
                this.dias = null;
                this.incapacidad.fecha_fin = null;
                if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                    this.$refs.FechaFinal.resetDate();
                }
            }
            else{
                this.dias = null;
                if(newData.length == 10){
                    this.diasDisable = false;
                }
                else{
                    this.diasDisable = true;
                }
            }
        },
        dias:function(newData, oldData) {
            if(newData != null && newData != '' && newData != undefined ){
                if (this.incapacidad.fecha_inicio != null && this.incapacidad.fecha_inicio.length == 10){
                    let fechaInicial = new Date(this.incapacidad.fecha_inicio);
                    let fechaFin = moment(this.sumarDias(fechaInicial,parseInt(newData))).format('YYYY-MM-DD');
                    this.incapacidad.fecha_fin = fechaFin.toString();
                    setTimeout(()=>this.$refs.FechaFinal.setDate(fechaFin.toString()),200);
                }
                
            }
        },
        'incapacidad.SAT_tipo_incapacidad_id':function(newData, oldData) {
            if(newData != null){
                let itemTipo = this.itemsTiposIncapacidades.find(item => item.id === newData)
                if(itemTipo){
                    if(
                        itemTipo.clave == '02' ||
                        itemTipo.clave == '03' ||
                        itemTipo.clave == '04' ||
                        itemTipo == null
                    ){
                        this.incapacidad.tipo_riesgo_id = null;
                        this.incapacidad.tipo_consecuencia_id =  null;
                        this.showCampo = false;
                        this.getIncapacidadesControl(newData);
                    }
                    else if(itemTipo.clave == '01'){
                        this.showCampo = true;
                        this.getTiposRiesgos(newData);
                    }
                }   
                
                if(oldData != null){
                    this.incapacidad.nomina_incapacidad_rama_id = null;
                }
                
                
            }
        }, 
        'incapacidad.tipo_riesgo_id':function(newData, oldData) {
            if(newData != null){
                this.getSecuelasConsecuencias(newData);
            }
        }, 
        'incapacidad.tipo_consecuencia_id':function(newData, oldData) {
            if(newData != null){
                this.getIncapacidadesControl(newData);
                if(oldData != null){
                    this.incapacidad.nomina_incapacidad_rama_id = null;
                }
            }
        },

    },
    computed: {
        activarPorcentaje: function(){
            if(this.incapacidad.nomina_incapacidad_rama_id != null && this.itemsControl.length != 0){
                let d_porcentaje = this.itemsControl.find(item => item.rama_id === this.incapacidad.nomina_incapacidad_rama_id);

                if(d_porcentaje.activar_porcentaje == false){
                    this.incapacidad.porcentaje = null;
                }

                return d_porcentaje.activar_porcentaje;

            }
        },
        activarFolio: function(){
            if(this.incapacidad.nomina_incapacidad_rama_id != null && this.itemsControl.length != 0){
                let d_folio = this.itemsControl.find(item => item.rama_id === this.incapacidad.nomina_incapacidad_rama_id);

                if(d_folio.activar_folio == false){
                    this.incapacidad.folio = null;
                }

                return d_folio.activar_folio;
            }
        }
    },
    methods: {
        existeFecha(fecha){
            let fechaf = fecha.split("-");
            let day = fechaf[2];
            let month = fechaf[1];
            let year = fechaf[0];
            let date = new Date(year,month,'0');
            if((day-0)>(date.getDate()-0)){
                return false;
            }
                return true;
        },
        sumarDias(fecha, dias){
            fecha.setDate(fecha.getDate() + dias);
            return fecha;
        },
        open(){
            document.getElementById("fileDocument").click();
        },
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            if (this.rol == "root") {
                this.getClientes();
            }
 
            if (this.rol == "admin") {
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
                this.filters.cliente_id = this.datosLogin.cliente_id;
            }

            if(this.rol == "admin-empresa"){
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
                this.incapacidad.empresa_id = this.datosLogin.empresa_id;
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
            }
            if(this.rol == "admin-sucursal"){
                this.incapacidad.cliente_id     = this.datosLogin.cliente_id;
                this.incapacidad.empresa_id     = this.datosLogin.empresa_id;
                this.incapacidad.sucursal_id    = this.datosLogin.sucursal_id;
                this.filters.cliente_id     = this.datosLogin.cliente_id;
                this.filters.empresa_id     = this.datosLogin.empresa_id;
                this.filters.sucursal_id    = this.datosLogin.sucursal_id;
            }
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        async abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoadingModal  = true;

            this.getIncapacidadesTipos();
            //this.getIncapacidadesControl();

            if(this.rol == 'root'){
                this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
                this.incapacidad.empresa_id = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
                this.incapacidad.empresa_id = this.datosLogin.empresa_id;
                this.incapacidad.sucursal_id = this.datosLogin.sucursal_id;
            }


            if(accion == "add"){
                this.tituloModal    = "Registrar incapacidad";
                this.imagen         = "/static/modal/incapacidadCreate.svg";
                this.isLoadingModal = false;
                
            } else {
                this.tituloModal                                = "Actualizar incapacidad";
                this.imagen                                     = "/static/modal/incapacidadUpdate.svg";
                this.incapacidad.id                             = data.id,
                this.incapacidad.cliente_id                     = data.cliente_id;
                this.incapacidad.empresa_id                     = data.empresa_id;
                this.incapacidad.sucursal_id                    = data.sucursal_id;
                this.incapacidad.empleado_id                    = data.empleado_id;
                this.incapacidad.SAT_tipo_incapacidad_id        = data.SAT_tipo_incapacidad_id;
                this.incapacidad.nomina_incapacidad_rama_id     = data.nomina_incapacidad_rama_id; //control
                this.incapacidad.fecha_inicio                   = data.fecha_inicio;
                //this.incapacidad.fecha_fin                      = data.fecha_fin;
                this.incapacidad.estatus                        = data.estatus;
                this.incapacidad.folio                          = data.folio;
                
                let difDias = moment(data.fecha_fin).diff(moment(data.fecha_inicio), 'days')
                setTimeout(()=>this.$refs.FechaInicio.setDate(data.fecha_inicio),200);
                setTimeout(()=>this.dias = difDias+1,300);
            }
            
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        async abrirModalUpdate(data){
            this.accion = "update";

            this.isLoadingModal  = true;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);

            await this.getIncapacidadesTipos();
            //this.getIncapacidadesControl();

            if(this.rol == 'root'){
                this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
                this.incapacidad.empresa_id = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
                this.incapacidad.empresa_id = this.datosLogin.empresa_id;
                this.incapacidad.sucursal_id = this.datosLogin.sucursal_id;
            }

            this.tituloModal                                = "Actualizar incapacidad";
            this.imagen                                     = "/static/modal/incapacidadUpdate.svg";
            this.incapacidad.id                             = data.id,
            this.incapacidad.cliente_id                     = data.cliente_id;
            this.incapacidad.empresa_id                     = data.empresa_id;
            this.incapacidad.sucursal_id                    = data.sucursal_id;
            this.incapacidad.empleado_id                    = data.empleado_id;
            this.incapacidad.SAT_tipo_incapacidad_id        = data.SAT_tipo_incapacidad_id;
            this.incapacidad.tipo_riesgo_id                 = data.tipo_riesgo_id;
            this.incapacidad.tipo_consecuencia_id           = data.tipo_consecuencia_id;
            this.incapacidad.nomina_incapacidad_rama_id     = data.nomina_incapacidad_rama_id; //control
            this.incapacidad.fecha_inicio                   = data.fecha_inicio;
            //this.incapacidad.fecha_fin                      = data.fecha_fin;
            this.incapacidad.estatus                        = data.estatus;
            this.incapacidad.folio                          = data.folio;
            this.incapacidad.porcentaje                     = data.porcentaje;
            this.incapacidad.dias                           = data.dias;
            
            let difDias = moment(data.fecha_fin).diff(moment(data.fecha_inicio), 'days')
            setTimeout(()=>this.$refs.FechaInicio.setDate(data.fecha_inicio),200);
            setTimeout(()=>this.dias = difDias+1,300);

            

            this.isLoadingModal = false;


        },
        cerrarModal() {
            this.$apollo.queries.empleados.skip = true;
            this.dialog     = false;
            this.dialogConsultar = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.incapacidad        = {
                id                  : null,
                cliente_id          : null,
                empresa_id          : null,
                sucursal_id         : null,
                empleado_id         : null,
                fecha_inicio        : null,
                fecha_fin           : null,
                estatus             : null,
                folio               : null,
                nomina_incapacidad_rama_id  : null, //control
                SAT_tipo_incapacidad_id  : null,
                tipo_riesgo_id      : null,
                tipo_consecuencia_id: null,
                porcentaje          : null,
                dias                : null,
            };
            this.itemIncapacidad         = {
                id                          : null,
                cliente_id                  : null,
                empresa_id                  : null,
                sucursal_id                 : null,
                empleado_id                 : null,
                SAT_tipo_incapacidad_id     : null,
                tipo_riesgo_id              : null,
                tipo_consecuencia_id        : null,
                nomina_incapacidad_rama_id  : null, //control
                fecha_inicio                : null,
                fecha_fin                   : null,
                estatus                     : null,
                folio                       : null,
                porcentaje                  : null,
                dias                        : null,
                empleado :{},
                cliente:{},
                empresa:{},
                sucursal:{},
                tipoIncapacidad:{},
                ramaIncapacidad:{}
            };
            this.registro_patronal_id       = null;
            this.diasDisable                = true;
            this.archivo                    = null;
            this.itemsClientes              = [];
            this.itemsEmpresas              = [];
            this.itemsSucursales            = [];
            this.itemsEmpleados             = [];
            this.itemsTiposIncapacidades    = [];
            this.itemsTiposRiesgos          = [];
            this.itemsSecuelasConsecuencias = [];
            this.itemsControl               = [];
            
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
            this.$nextTick(() => {
                if(this.$refs.form != null){
                    this.$refs.form.reset();
                }
                
            });
        },
        async loadModalData() {
            await this.resetValues();
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
            this.getIncapacidadesTipos();
            if(this.rol == 'root'){
                this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
                this.incapacidad.empresa_id = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                this.incapacidad.cliente_id = this.datosLogin.cliente_id;
                this.incapacidad.empresa_id = this.datosLogin.empresa_id;
                this.incapacidad.sucursal_id = this.datosLogin.sucursal_id;
            }
        },
        setFilters() {

            this.filters = {};
            this.isLoading = true;

            let cliente = this.incapacidad.cliente_id;
            let empresa = this.incapacidad.empresa_id;
            let registro_patronal = this.registro_patronal_id;
            let sucursal = this.incapacidad.sucursal_id;
            let empleado = this.incapacidad.empleado_id;
            let fecha_inicio = this.incapacidad.fecha_inicio;
            let fecha_final =  this.incapacidad.fecha_fin;
            let estatus = this.incapacidad.estatus;
            let tipo_id = this.incapacidad.SAT_tipo_incapacidad_id; //Ramo de seguro
            let riesgo_id = this.incapacidad.tipo_riesgo_id;
            let consecuencia_id = this.incapacidad.tipo_consecuencia_id;
            let rama_id = this.incapacidad.nomina_incapacidad_rama_id; //control
            
            

            if (this.rol == "admin") {    
                cliente = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                cliente = this.datosLogin.cliente_id;
                empresa= this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
            }


            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.cliente_id     = cliente;
            }
            if(registro_patronal != null && registro_patronal != undefined && registro_patronal != ""){
                this.filters.registro_patronal_id     = registro_patronal;
            }
            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.empresa_id     = empresa;
            }
            if(sucursal != null && sucursal != undefined && sucursal != ""){
                this.filters.sucursal_id    = this.datosLogin.sucursal;
            }
            if(empleado != null && empleado != undefined && empleado != ""){
                this.filters.empleado_id = empleado;
            }
            if(fecha_inicio != null && fecha_inicio != undefined && fecha_inicio != ""){
                this.filters.min_fecha_inicio = fecha_inicio;
            }
            if(fecha_final != null && fecha_final != undefined && fecha_final != ""){
                this.filters.max_fecha_inicio = fecha_final;
            } 
            if(estatus != null && estatus != undefined && estatus != ""){
                this.filters.estatus = estatus
            }
            if(tipo_id != null && tipo_id != undefined && tipo_id != ""){ //Ramo de seguro
                this.filters.tipo_id = tipo_id;
            }
            if(riesgo_id != null && riesgo_id != undefined && riesgo_id != ""){
                this.filters.riesgo_id = riesgo_id;
            }
            if(consecuencia_id != null && consecuencia_id != undefined && consecuencia_id != ""){
                this.filters.consecuencia_id = consecuencia_id;
            }
            if(rama_id != null && rama_id != undefined && rama_id != ""){  //Control
                this.filters.rama_id = rama_id;
            }
            
            this.resetValues();
        },
        eliminar(data){
            let self = this;
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar la incapacidad?",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiIncapacidades.delete(data.id).then(response => {
                        Notify.Success("Incapacidad eliminada", "La incapacidad ha sido eliminada satisfactoriamente");
                        //this.$apollo.queries.incapacidades.refetch();
                        self.$refs.tabla.getData();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        async guardar() {
            let self = this;
            this.$refs.form.validate().then(async success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                var f_inicio = new Date(this.incapacidad.fecha_inicio);
                var f_final = new Date(this.incapacidad.fecha_fin);

                if (f_inicio > f_final) {
                    Notify.ErrorToast("Rango de fechas incorrectas.");
                    this.isSaving = false;
                    return;
                } 

                let formData = new FormData();
                formData.append('cliente_id', this.incapacidad.cliente_id);
                formData.append('empresa_id', this.incapacidad.empresa_id);
                formData.append('sucursal_id', this.incapacidad.sucursal_id);
                formData.append('empleado_id', this.incapacidad.empleado_id);
                formData.append('nomina_incapacidad_rama_id', this.incapacidad.nomina_incapacidad_rama_id); //control
                formData.append('SAT_tipo_incapacidad_id', this.incapacidad.SAT_tipo_incapacidad_id);
                formData.append('tipo_riesgo_id', (this.incapacidad.tipo_riesgo_id != null ? this.incapacidad.tipo_riesgo_id:''));
                formData.append('tipo_consecuencia_id', (this.incapacidad.tipo_consecuencia_id != null ? this.incapacidad.tipo_consecuencia_id:''));
                formData.append('estatus', this.incapacidad.estatus.toString());
                formData.append('fecha_inicio', this.incapacidad.fecha_inicio.toString());
                formData.append('fecha_fin', this.incapacidad.fecha_fin.toString());
                formData.append('folio', (this.incapacidad.folio != null ? this.incapacidad.folio : ''));
                formData.append('porcentaje', (this.incapacidad.porcentaje != null ? this.incapacidad.porcentaje : ''));
                formData.append('dias', (this.dias != null ? this.dias : ''));
                if(this.archivo != null){
                    formData.append('archivo', this.archivo);
                }
                
                this.isLoading = true;
                if(this.accion === "add") {
                    apiIncapacidades.store(formData).then((response) => {
                        Notify.Success("Operación exitosa", "La incapacidad se guardó satisfactoriamente.");
                        //this.$apollo.queries.incapacidades.refetch();
                        self.$refs.tabla.getData();
                        this.cerrarModal();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    formData.append('id', this.incapacidad.id);

                    let showMensaje =  null;
                    let errorConsulta = null;

                    let paramConsulta = {
                        empleado_id : this.incapacidad.empleado_id,
                        fecha_inicio: this.incapacidad.fecha_inicio.toString(),
                        fecha_fin   : this.incapacidad.fecha_fin.toString()

                    }
                    await apiIncapacidades.consultarActualizar(paramConsulta).then( response => {
                        console.log(response.resultado);
                        showMensaje = response.resultado;

                    })
                    .catch(err => {
                        errorConsulta = true;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }

                    });

                    if(errorConsulta == true){
                        return;
                    }

                    if(showMensaje == true){
                        Notify.Alert(
                            "ADVERTENCIA",
                            "¿Estás seguro que deseas modificar la incapacidad? Esta acción afectará algunos resultados de los cálculos.",
                            "Actualizar",
                            () => {
                                apiIncapacidades.update(formData).then((response) => {
                                    Notify.Success("Operación exitosa", "La incapacidad se actualizó satisfactoriamente.");
                                    //this.$apollo.queries.incapacidades.refetch();
                                    self.$refs.tabla.getData();
                                    self.cerrarModal();
                                })
                                .catch(err => {
                                    self.isLoading = false;
                                    self.isSaving = false;
                                    if(typeof err.response.data.error === 'string'){
                                        Notify.ErrorToast(err.response.data.error);
                                    }else{
                                        let error = Object.values(err.response.data.error);
                                        let leyenda = ``;

                                        for (var i = 0; i < error.length; i++) {
                                            leyenda+= `* ` + error[i] + `\n`;
                                        }

                                        Notify.ErrorToast(leyenda);
                                    }
                                });
                            },
                            () =>{
                                self.isLoading = false;
                                self.isSaving = false;
                            }
                        )

                    }
                    else{
                        apiIncapacidades.update(formData).then((response) => {
                            Notify.Success("Operación exitosa", "La incapacidad se actualizó satisfactoriamente.");
                            //this.$apollo.queries.incapacidades.refetch();
                            self.$refs.tabla.getData();
                            self.cerrarModal();
                        })
                        .catch(err => {
                            self.isLoading = false;
                            self.isSaving = false;
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }

                                Notify.ErrorToast(leyenda);
                            }
                        });
                    }
                }
            });
        },
        async getClientes(){
            let parametros = { activo:true, paginate:false }
            await apiClientes.getClientes(parametros).then((response) => {
                this.itemsClientes = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Clientes.");
            });
        },
        async cambiaCliente(cliente, accion = null){
            if (this.incapacidad.cliente_id != cliente) {
                this.incapacidad.empresa_id = null;
            }
            if(cliente == null){
                return;
            }
            let param = {activo: true, paginate: false, cliente_id: cliente != null?cliente:this.datosLogin.cliente_id};
            await apiEmpresas.getEmpresas(param).then((response) => {
                this.itemsEmpresas = response.data;
                //this.empresa_value = null;
            })
            .catch(err => {        
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.")
            });
        },
        async cambiaEmpresa(empresa, accion = null){
            if (this.incapacidad.empresa_id != empresa) {
                this.incapacidad.sucursal_id = null;
            }
            
            let parametros = {activo:true, paginate:false, empresa_id:empresa != null?empresa:this.datosLogin.empresa_id};
            await apiSucursales.getSucursales(parametros).then((response) => {
                this.itemsSucursales = response.data;
                //this.sucursal_value = null;
            })
            .catch(err =>{    
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.")
            })
        }, 
        async cambiaSucursal(sucursal, accion = null){
            if (this.incapacidad.sucursal_id != sucursal) {
                this.incapacidad.empleado_id = null;
            }
            if(sucursal == null){
                this.itemsEmpleados = [];
                this.incapacidad.empleado_id = null;
                return;
            }
            this.filtersEmpleados = {AND:[]};
            this.filtersEmpleados.AND.push({column:'CLIENTE_ID', value:this.incapacidad.cliente_id});
            this.filtersEmpleados.AND.push({column:'EMPRESA_ID', value:this.incapacidad.empresa_id});
            this.filtersEmpleados.AND.push({column:'SUCURSAL_ID', value:this.incapacidad.sucursal_id});
            this.$apollo.queries.empleados.skip = true;
            this.$apollo.queries.empleados.skip = false;
        },
        async getIncapacidadesTipos(){
            let parametros = {
                activo:true, 
                paginate:false,
                order:'asc'
            };
            await apiIncapacidadesTipos.find(parametros).then((response) => {
                this.itemsTiposIncapacidades = response.data;
            })
            .catch(err =>{    
                Notify.ErrorToast("Ocurrió un error al cargar los tipos de incapacidades.")
            })
        },
        getTiposRiesgos(){
            let parametros = {
                activo:true, 
                paginate:false,
                order:'asc',
                orderBy:'clave',
                SAT_tipo_incapacidad_id:this.incapacidad.SAT_tipo_incapacidad_id
            };

            apiIncapacidadesTipoRiesgo.find(parametros).then((response) => {
                this.itemsTiposRiesgos = response.data;
            })
            .catch(err =>{    
                Notify.ErrorToast("Ocurrió un error al cargar los tipos de incapacidades.")
            })
        },
        getSecuelasConsecuencias(){
            let parametros = {
                activo:true, 
                paginate:false,
                order:'asc',
                orderBy:'clave',
                tipo_riesgo_id:this.incapacidad.tipo_riesgo_id
            };
            apiIncapacidadesSecuelaconsecuencia.find(parametros).then((response) => {
                this.itemsSecuelasConsecuencias = response.data;
            })
            .catch(err =>{    
                Notify.ErrorToast("Ocurrió un error al cargar los tipos de incapacidades.")
            })
        },
        getIncapacidadesControl(){
            let parametros = {
                //activo:true, // hay que comentarlo para que funcione la API
                paginate:false,
                order:'asc',
                orderBy:'clave',
                consecuencia_id:this.incapacidad.tipo_consecuencia_id,
                tipo_riesgo_id:this.incapacidad.tipo_riesgo_id, 
                SAT_tipo_incapacidad_id:this.incapacidad.SAT_tipo_incapacidad_id
            };
            apiIncapacidadesControl.find(parametros).then((response) => {
                this.itemsControl = response.data;
            })
            .catch(err =>{    
                Notify.ErrorToast("Ocurrió un error al cargar las ramas.")
            })
        },
        async descargar(item = null) {
            let self = this;
            this.isLoadingDownload = true;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url     = "incidencias/download";
            var nombre_archivo  = item.url_archivo.split("/");
            nombre_archivo      = nombre_archivo[nombre_archivo.length - 1];
            nombre_archivo      = nombre_archivo.split(".");
            nombre_archivo      = this.itemIncapacidad.empleado.nombre_completo + "." + nombre_archivo[1];
            let parametros      = { url_archivo: item.url_archivo };

            try {
                axios({
                method: "post",
                url: url,
                responseType: "blob",
                data: parametros,
                }).then(function(response) {
                    FileDownload(response.data, nombre_archivo);
                    self.isLoadingDownload = false;
                });
            } catch (error) {
                console.log(error);
                Notify.ErrorToast("Ocurrió un error al descargar el archivo.")
                self.isLoadingDownload = false;
            }
        },
        openModalInfo(data){
            this.isLoadingConsultar = true;
            this.idIncapacidad = data.id;
            this.$apollo.queries.incapacidad.skip = true;
            this.$apollo.queries.incapacidad.skip = false;
            setTimeout(function(){ document.getElementById("btnModalConsultar").click(); }, 200);
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        formatoEstatus(estatus){
            switch (estatus) {
                case 'REVISION':
                    return 'Revisión';
                    break;
                case 'RECHAZADO':
                    return 'Rechazado';
                    break;
                case 'AUTORIZADO':
                    return 'Autorizado';
                    break;
                case 'CAPTURA':
                    return 'Captura';
                    break;
                case 'PENDIENTE':
                    return 'Pendiente';
                    break;
                default:
                    return '';
                    break;
            }
        },
        abrirModalDoc(data){
            this.accion     = 'update';
            this.idIncapacidad = data.id;
            this.$apollo.queries.incapacidad.skip = true;
            this.$apollo.queries.incapacidad.skip = false;
            setTimeout(function(){ document.getElementById("btnModalDocumento").click(); }, 100);
        },
        cerrarModalDocumento(){
            this.dialogDocumento = false;
            this.archivo = null;
            this.isSaving = false;
            this.$nextTick(() => {
                if(this.$refs.formDoc != null){
                    this.$refs.formDoc.reset();
                }
            });

        },
        guardarDocumento(){
            let formData = new FormData();
            this.isSaving = true;
            formData.append('incapacidad_id', this.incapacidad.id);
            
            if(this.archivo != null){
                formData.append('archivo', this.archivo);
            }
            apiIncapacidades.updateFile(formData).then((response) => {
                Notify.Success("Excelente!", "El documento se actualizó satisfactoriamente.");
                this.$apollo.queries.incapacidad.refetch();
                this.cerrarModalDocumento();
            })
            .catch(err => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                    this.cerrarModalDocumento();
                }
            });
        },
        formatoEstatus(estatus){
            switch (estatus) {
                case 'REVISION':
                    return 'Revisión';
                    break;
                case 'RECHAZADO':
                    return 'Rechazado';
                    break;
                case 'AUTORIZADO':
                    return 'Autorizado';
                    break;
                case 'CAPTURA':
                    return 'Captura';
                    break;
                case 'PENDIENTE':
                    return 'Pendiente';
                    break;
                default:
                    return '';
                    break;
            }
        },
        colorEstatus(estatus){
            switch (estatus) {
                case 'REVISION':
                    return '#FCAA7C';
                    break;
                case 'RECHAZADO':
                    return '#F86868';
                    break;
                case 'AUTORIZADO':
                    return '#86EF83';
                    break;
                case 'CAPTURA':
                    return '#FCAA7C';
                    break;
                case 'PENDIENTE':
                    return '#FED543';
                    break;
                default:
                    return '';
                    break;
            }
        },
        presicionNumeroPorcentaje(event, valor){
            if (/\.\d{2}/.test(valor)) 
                return event.preventDefault();
        },
        async getRegistroPatronal(){
            if(this.incapacidad.cliente_id == null){
                return;
            }
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.incapacidad.cliente_id }
            await apiRegistroPatronal.find(parametros).then((response) => {
                this.itemsRegistrosPatronales = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },
        exportar(){

            const FileDownload = require("js-file-download");
            var axios = require('axios');
            
            let parametros = this.filters;
            //parametros.tipo_archivo = 'incapacidades';
           

            this.loadingLottie = true;
            this.fraseLoading = "Descargando";

            let nombre;
            nombre = "Incapacidades-Movimientos.zip"; //cambiar por zip
            let self = this;

            let url = "nominas-incapacidades/exportar";
            try {
                axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.loadingLottie = false;
                })
                .catch(async (error) =>{
                    self.loadingLottie = false;
                    console.log(error);
                    let errorString = error.response;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response instanceof Blob &&
                        error.response.type &&
                        error.response.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                    
                });
            } 
            catch (err) {
                self.loadingLottie = false;
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
            }

        },

        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("nominas-incapacidades.store")
            this.permisos.update = permisosStorage.includes("nominas-incapacidades.update")
            this.permisos.delete = permisosStorage.includes("nominas-incapacidades.destroy")
        },
    },
    created(){
        this.listar();
        this.permisosVista();
    },
    mounted(){
        //this.$apollo.queries.incapacidades.skip = false;
    },
    updated() {  
        document.querySelector('#tableScroll tbody').style.minWidth   = "1250px";
        document.querySelector('#tableScroll thead tr th:nth-child(6)').style.paddingLeft   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(6)').style.width   = "80px";
    },
    apollo: {
        incapacidades: {
            query       : queries.incapacidadesTableQuery,
            fetchPolicy : 'no-cache',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'id',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.tableData  = this.incapacidades;
            },
        },
        empleados: {
            query       : queries.getEmpleadosQuery,
            fetchPolicy : 'no-cache',
            variables() {
                return {
                    whereConditions : this.filtersEmpleados,
                    numberItems : 10000,
                    numberPage  : 1,
                    fieldOrder  : 'nombre',
                    Order       : 'ASC',
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                if(this.empleados.data){
                    this.empleados.data.forEach(item =>{
                        item.nombre_completo = item.nombre + ' ' + item.apellido_paterno + ' ' + item.apellido_materno;
                    });
                    this.itemsEmpleados = this.empleados.data;
                }

                if(this.accion == 'update'){
                    this.isLoadingModal  = false;
                }
                
            },
        },
        incapacidad: {
            query       : queries.getIncidenciaQuery,
            fetchPolicy : 'no-cache',
            variables() {
                return {
                    id : parseInt(this.idIncapacidad)
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoadingConsultar = false;
                this.itemIncapacidad = this.incapacidad;
            }
        },
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
    }
    .text{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
    }

    .btnAzul.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        text-transform: capitalize;
        transition: 0.3s;
        height: 42px !important;
        background: #58C5D8;
        border-radius: 50px;
    }

    .btnAzul.theme--light.v-btn:hover {
        background: #2DB9D1 !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
        -moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
    }

</style>
